import info1 from '../../images/app.png';
import info1_1 from '../../images/appstore.png';
import info1_2 from '../../images/googleplay.png';
import info1_3 from '../../images/wave-3.svg';
import info1_4 from '../../images/bg-1.svg';
//import info2_1 from '../../images/businessshop.svg';
import info2_1 from '../../images/goshopping.svg';

export const homeObjOne = {
  id: 'aplicacion',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Aplicación Móvil',
  headline: 'Descarga nuestra app',
  description: 'Terminó la espera, ya puedes vivir una nueva experiencia de cocina junto a nuestra app cukap.',
  buttonLabel: 'Conoce Más',
  imgStart: true,
  img: info1,
  alt: 'app',
  img1: info1_1,
  alt1: 'appstorelogo',
  img2: info1_2,
  alt2: 'googleplaylogo',
  img3: info1_3,
  alt3: 'background',
  img4: info1_4,
  alt4: 'background2',
  dark: false,
  primary: false,
  darkText: true,
  showButton: false,
  showAppStoreButton: true,
  goTo: 'services',
  shortPage: true,
  topWave: true,
  bottomWave: true
};

export const homeObjTwo = {
  id: 'shop',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Shop Especializado',
  headline: '¿Tienes algún producto novedoso?',
  description: 'Contáctanos a contacto@cukap.cl o agenda una reunión en el siguiente link y veremos como podemos ayudarte.',
  buttonLabel: 'Agenda una reunión',
  imgStart: false,
  img: info2_1,
  alt: 'app',
  img1: info1_1,
  alt1: 'appstorelogo',
  img2: info1_2,
  alt2: 'googleplaylogo',
  img3: info1_3,
  alt3: 'background',
  img4: info1_4,
  alt4: 'background2',
  dark: false,
  primary: false,
  darkText: true,
  showButton: true,
  showAppStoreButton: false,
  goTo: 'services',
  shortPage: true,
  topWave: false,
  bottomWave: false,
};