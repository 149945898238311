import React from 'react';

import logo from '../../images/LOGO_white.png';

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
  ImgWrap,
  Img
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='caracteristicas' onClick={toggle}>Características</SidebarLink>
          <SidebarLink to='aplicacion' onClick={toggle}>Aplicación</SidebarLink>
          <SidebarLink to='shop' onClick={toggle}>Shop</SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to='/signin'>Sign In</SidebarRoute>
        </SideBtnWrap>
        <ImgWrap>
          <Img src={logo} alt={'logo'}/>
        </ImgWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
