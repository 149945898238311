import React from 'react';

import Icon1 from '../../images/drag.svg';
import Icon2 from '../../images/shopping.svg'; 
import Icon3 from '../../images/syncfiles.svg'; 
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id={'caracteristicas'}>
      <ServicesH1>Características</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1}/>
          <ServicesH2>Fácil Acceso a contenido</ServicesH2>
          <ServicesP>Una aplicación móvil con acceso inmediato a recetas, tips, contenido, videos, etc.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2}/>
          <ServicesH2>Shop Especializado</ServicesH2>
          <ServicesP>Conoce las últimas novedades y tendencias para ocupar junto a tu robot de cocina.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3}/>
          <ServicesH2>Recetario en tu teléfono</ServicesH2>
          <ServicesP>Lleva a todas partes tus recetas favoritas. Explora, guarda y crea recetas.</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  )
}

export default Services
