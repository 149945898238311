import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import Home from './pages';
import SigninPage from './pages/signin';
import Calendar from './pages/calendar';
import Politica from './pages/politica';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/signin' component={SigninPage} exact />
        <Route path='/calendar' component={Calendar} exact />
        <Route path='/politica-de-privacidad' component={Politica} exact />
      </Switch>
    </Router>
  );
}

export default App;
