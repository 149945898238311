import React from 'react';
import { InlineWidget } from 'react-calendly';

import {
  Container,
  HeroContainer,
  HeroBg,
  ImageBg,
  Title,
  ContentWrapper,
} from './CalendarElements'

import bg1 from '../../images/bg-1.svg';

const Calendar = () => {

  return (
    <> 
      <Container>

        <HeroContainer>
          <HeroBg>
            <ImageBg
              src={bg1}
              alt={'bg-1'}
            />
          </HeroBg>
          <Title>Agenda de Cukap</Title>
        </HeroContainer>

        <ContentWrapper>
          <InlineWidget
            url='https://calendly.com/cukap/30min'
            styles={{
              height: 650,
              width: '100%',
              marginTop: -100,
              borderRadius: 10
            }}
            pageSettings={{
              hideEventTypeDetails: false,
              hideLandingPageDetails: true,
              primaryColor: '#192685',
              textColor: '4d5055',
            }}
          />
        </ContentWrapper>
      </Container>
    </>
  )
}

export default Calendar
