import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(127, 181, 181);
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  width: '100%';
`;

export const HeroContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 90%,
    rgba(128, 181, 181, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  position: relative;
  width: 100%;
  
  
  @media screen and (max-width: 768px) {
    height: 500px;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  -o-bject-fit: cover;
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  justify-content: center;
  position: relative;
`;

export const ImportantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -100px;
  background: white;
  border-radius: 15px;
  margin-left: 100px;
  margin-right: 100px;
  padding: 20px;
  z-index: 1;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const Title = styled.h1`
  width: 100%;
  padding: 30px;
  color: grey;
  font-size: 48px;
  text-align: center;
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle1 = styled.p`
  margin-top: 60px;
  color: white;
  font-size: 24px;
  text-align: left;
  margin-left: 100px;
  margin-right: 100px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Subtitle2 = styled.p`
  margin-top: 24px;
  color: white;
  font-size: 20px;
  text-align: left;
  margin-left: 130px;
  margin-right: 100px;
  text-decoration-line: underline;

  @media screen and (max-width: 768px) {
    margin-left: 40px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const Paragraph1 = styled.p`
  margin-top: 24px;
  color: white;
  font-size: 18px;
  text-align: justify;
  margin-left: 100px;
  margin-right: 100px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

export const Paragraph2 = styled.p`
  margin-top: 24px;
  color: white;
  font-size: 18px;
  text-align: justify;
  margin-left: 130px;
  margin-right: 100px;

  @media screen and (max-width: 768px) {
    margin-left: 40px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

export const Paragraph3 = styled.p`
  color: grey;
  font-size: 15px;
  text-align: left;
`;

export const Paragraph4 = styled.p`
  margin-top: 24px;
  color: grey;
  font-size: 15px;
  text-align: justify;
`;

export const Icon = styled(Link)`
`;

export const ImgWrap = styled.div`
  max-width: 200px;
  margin-bottom: 100px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;