import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';

import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  ImgWrap,
  Img,
  InfoBg2,
  ImageBg,
  NavBtnLink
} from './Footer2Elements';

import logo2 from '../../images/LOGO_1.png'
import img from '../../images/wave-3.svg'

const Footer2 = () => {
  
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer id={'footer'}>
      <InfoBg2>
        <ImageBg src={img} alt={'wave'} />
      </InfoBg2>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/politica-de-privacidad' onClick={toggleHome}>
              <ImgWrap>
                <Img src={logo2} alt={'logo2'}/>
              </ImgWrap>
            </SocialLogo>
            <WebsiteRights>cukap @ {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            <NavBtnLink to='/politica-de-privacidad'>Política de Privacidad</NavBtnLink>
            <SocialIcons>
              <SocialIconLink href='//www.facebook.com/Cukap-105920464704858' target='_blank' aria-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='//www.instagram.com/cukap.cl' target='_blank' aria-label='Instagram'>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href='//www.youtube.com/cukap' target='_blank' aria-label='Youtube'>
                <FaYoutube />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer2