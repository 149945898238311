import React from 'react';
import { Button } from '../ButtonElement';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  Img,
  Img1,
  ImgWrap1,
  LogoWrapper,
  InfoBg,
  ImageBg,
  InfoBg2,
  BtnWrap
} from './InfoElements';

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  img,
  alt,
  shortPage,
  img1,
  alt1,
  img2,
  alt2,
  img3,
  alt3,
  img4,
  alt4,
  topWave,
  bottomWave,
  showButton,
  showAppStoreButton,
  primary,
  dark,
  dark2,
  buttonLabel
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        {topWave &&
          <InfoBg>
            <ImageBg src={img3} alt={alt3} />
          </InfoBg>
        }
        <InfoWrapper shortPage={shortPage}>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine lightBg={lightBg}>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                {showButton && <BtnWrap>
                  <Button
                    to='/calendar'
                    smooth={true}
                    duration={500}
                    exact='true'
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                </BtnWrap>}
                {showAppStoreButton && <LogoWrapper>
                  <ImgWrap1>
                    <a href={'https://apps.apple.com/us/app/cukap/id1546151379'}>
                      <Img1 src={img1} alt={alt1}/>
                    </a>
                  </ImgWrap1>
                  <ImgWrap1>
                    <a href={'https://play.google.com/store/apps/details?id=com.nuevaurbana.cukap'}>
                      <Img1 src={img2} alt={alt2}/>
                    </a>
                  </ImgWrap1>
                </LogoWrapper>}
              </TextWrapper>
            </Column1>
            <Column2>
              <Img src={img} alt={alt}/>
            </Column2>
          </InfoRow>
        </InfoWrapper>
        {bottomWave &&
          <InfoBg2>
            <ImageBg src={img4} alt={alt4} />
          </InfoBg2>
        }
      </InfoContainer>
    </>
  )
}

export default InfoSection
