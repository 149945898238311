import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(127, 181, 181);
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  width: '100%';
`;

export const HeroContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 90%,
    rgba(128, 181, 181, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  position: relative;
  width: 100%;
  
  
  @media screen and (max-width: 768px) {
    height: 300px;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  -o-bject-fit: cover;
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  justify-content: center;
  position: relative;
`;

export const Title = styled.h1`
  width: 100%;
  color: white;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Paragraph1 = styled.p`
  margin-top: 24px;
  color: white;
  font-size: 18px;
  text-align: center;
  margin-left: 100px;
  margin-right: 100px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;