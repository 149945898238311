import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  ImgWrap,
  Img
} from './NavbarElements';

import logo1 from '../../images/LOGO_2.png';
import logo2 from '../../images/LOGO_2white.png';

const Navbar = ({ toggle }) => {

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: scrollNav ? 'white' : '#7fb5b5' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to='/' onClick={toggleHome} scrollNav={scrollNav}>
              <ImgWrap>
                <Img src={scrollNav ? logo2 : logo1} alt={'logo1'}/>
              </ImgWrap>
            </NavLogo>
            
            <MobileIcon onClick={toggle} scrollNav={scrollNav}>
              <FaBars />
            </MobileIcon>
            
            <NavMenu>
              <NavItem>
                <NavLinks
                  to='caracteristicas'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollNav={scrollNav}
                >
                  Características
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='aplicacion'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollNav={scrollNav}
                >
                  Aplicación
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='shop'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollNav={scrollNav}
                >
                  Shop
                </NavLinks>
              </NavItem>
            </NavMenu>

            <NavBtn>
              <NavBtnLink scrollNav={scrollNav} to='/signin'>Sign In</NavBtnLink>
            </NavBtn>

          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
