import React, { useState } from 'react'

import PoliticaDePrivacidad from '../components/PoliticaDePrivacidad'
import Footer2 from '../components/Footer2';
import Navbar2 from '../components/Navbar2';

const Politica = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar2 toggle={toggle}/>
      <PoliticaDePrivacidad />
      <Footer2 />
    </>
  )
}

export default Politica
