import React, { useState } from 'react';

import {
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  ImageBg,
  CalendarButton,
  ButtonsWrapper,
  VideoButton,
  InfoRow,
  Column1,
  Column2,
  ImgWrap,
  Img,
  ImgWrap2,
  Img2,
  InfoWrapper
} from './HeroElements'

import bg1 from '../../images/bg-1.svg';
import app1 from '../../images/cooking-2.svg';

const HeroSection = () => {

  const [hoverCalendar, setHoverCalendar] = useState(false);

  const onHoverCalendar = () => {
    setHoverCalendar(!hoverCalendar)
  };

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <ImageBg
          src={bg1}
          alt={'bg-1'}
        />
      </HeroBg>
      <InfoWrapper>
        <InfoRow>
          <Column1>
            <HeroContent>
              <HeroH1>Recetas, tips, contenido, shop y mucho más!</HeroH1>
              <HeroP>Todo lo que necesitas para potenciar el uso de tu robot de cocina, en un solo lugar.</HeroP>
            </HeroContent>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={app1} alt={'cooking'}/>
            </ImgWrap>
          </Column2>
        </InfoRow>
      </InfoWrapper>
      
      
    </HeroContainer>
  )
}

export default HeroSection
