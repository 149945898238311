import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';

export const HeroContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 90%,
    rgba(128, 181, 181, 1) 100%
  );
  /* background: white; */
  display: flex;
  //justify-content: left;
  align-items: center;
  //padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;
  
  @media screen and (max-width: 768px) {
    height: 900px;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  -o-bject-fit: cover;
  object-fit: cover;
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  //height: 800px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  //padding: 0 24px;
  justify-content: center;
  //background: red;

  @media screen and (max-width: 768px) {
    height: 800px;
    margin-top: 150px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: 'col1 col2';

  @media screen and (max-width: 768px) {
    grid-template-areas: 'col1 col1' 'col2 col2';
    //margin-top: -100px;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  //background: black;
`;

export const ImgWrap = styled.div`
  max-width: 455px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  -o-bject-fit: cover;
  object-fit: cover;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  //position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const HeroH1 = styled.h1`
  color: grey;
  font-size: 48px;
  text-align: left;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 48px;
    color: grey;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
    color: grey;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  //color: rgba(77, 80, 85, 1);
  color: rgba(0, 0, 0, 0.8);
  font-size: 24px;
  text-align: left;
  max-width: 600px;
  font-weight: 100;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;



export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  //background: black;
  margin-top: 32px;

  @media screen and (max-width: 768px) {
    display: grid
  }
`;

export const HeroBtnWrapper = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: red;
  
  @media screen and (max-width: 768px) {
    margin-top: 10px;
    align-items: flex-start;
  }
`;

export const CalendarButton = styled(Link)`
  border-radius: 50px;
  background: #ff8000;
  white-space: nowrap;
  padding: 14px 48px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(77, 80, 85, 1);
  }

  @media screen and (max-width: 768px) {
    padding: 10px 28px;
    margin-right: 5px;
    margin-top: 32px;
    background: white;
    color: #ff8000;
  }
`;

export const VideoButton = styled(Link)`
  border-radius: 50px;
  background: transparent;
  white-space: nowrap;
  padding: 14px 48px;
  color: grey;
  font-size: 16px;
  outline: none;
  border: 2px;
  border: #ff8000;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;
  //margin-top: 10;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: rgba(77, 80, 85, 1);
  }

  @media screen and (max-width: 768px) {
    padding: 10px 28px;
    margin-right: 5px;
    //background: white;
    color: #fff;
  }
`;

export const ImgWrap2 = styled.div`
  max-width: 150px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Img2 = styled.img`
  width: 100%;
  height: 100%;
  //margin: 0 0 10px 0;
  //padding-right: 0;
  //-o-bject-fit: cover;
  object-fit: contain;
`;