import React, { useState } from 'react'

import SignIn from '../components/Signin'
import Footer2 from '../components/Footer2';
import Navbar2 from '../components/Navbar2';

const SigninPage = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar2 toggle={toggle}/>
      <SignIn />
      <Footer2 />
    </>
  )
}

export default SigninPage
