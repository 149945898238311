import React from 'react'
import {
  Container,
  Title,
  Paragraph1,
  Paragraph2,
  Paragraph3,
  Paragraph4,
  ImportantWrapper,
  Subtitle1,
  Subtitle2,
  HeroContainer,
  HeroBg,
  ImageBg,
  ContentWrapper
} from './PoliticaDePrivacidadElements';

import bg1 from '../../images/bg-1.svg';

const SignIn = () => {
  return (
    <>
      <Container>

        <HeroContainer>
          <HeroBg>
            <ImageBg
              src={bg1}
              alt={'bg-1'}
            />
          </HeroBg>
          <Title>Política de Privacidad</Title>
        </HeroContainer>
        
        <ContentWrapper>
          <ImportantWrapper>
            <Paragraph3>
              Fecha de la última revisión: 23 de diciembre de 2020.
            </Paragraph3>
            <Paragraph4>
              La política de privacidad CUKAP se incorpora y forma parte de los términos de servicio ( https://www.cukap.cl/politica-de-privacidad/ ).
              Por favor lea atentamente este documento. Contiene información importante que usted debe saber antes de usar nuestro Sitio Web,
              App Móvil, y/o servicios. Al continuar utilizando el Sitio Web, App Móvil y los servicios de CUKAP, se considerará que ha aceptado
              esta política de privacidad.
            </Paragraph4>
          </ImportantWrapper>
          <Subtitle1 id='generalidades'>
            Generalidades
          </Subtitle1>
          <Paragraph2>
            La presente normativa entra en vigencia desde el momento en que cualquier persona accede, hace uso de nuestra página web y/o uso de
            nuestros servicios de software, App Móvil y productos; y tiene como objetivo describir cómo utilizamos y procesamos su información
            personal y cookies. Al utilizar el sitio web y/o App Móvil de CUKAP, sus productos y servicios usted acepta automáticamente la
            presente “Política de Privacidad” y los “Términos de Servicio”.
          </Paragraph2>
          <Paragraph2>
            También encontrará nuestros datos de contacto, a fin de poder aclarar cualquier duda al respecto.
          </Paragraph2>
          <Paragraph2>
            La privacidad de la información de nuestros proveedores y usuarios es de suma importancia para CUKAP razón por la cual tomamos los
            resguardos y precauciones necesarias para resguardar su información.
          </Paragraph2>
          <Paragraph2>
            Como parte normal de nuestra actividad y los servicios que prestamos, recogemos y en algunos casos excepcionales revelamos información
            total o parcial de los Usuarios recopilada por nuestra Plataforma Web, App Móvil y servicios de software.
          </Paragraph2>
          <Paragraph2>
            CUKAP se reserva el derecho de modificar estas políticas de privacidad sin previo aviso, y disponibilizará los cambios en el Sitio Web y/o App Móvil.
            Por lo tanto, es recomendable que las revise regularmente si esta política de privacidad se ha modificado desde la última vez que usted
            accedió al sitio web o utiliza nuestros productos y servicios, ya que se entiende que usted acepta automáticamente dichos cambios una
            vez que haya hecho un uso continuado del Sitio Web y/o App Móvil.
          </Paragraph2>
          <Paragraph2>
            Si como resultado de los cambios en la Política de Privacidad usted desea modificar la forma en que se permite a CUKAP utilizar su
            información personal, puede hacerlo siguiendo el procedimiento descrito en las opciones disponibles de esta Política de Privacidad.
          </Paragraph2>
          <Paragraph2>
            Si usted no está de acuerdo con estas políticas de privacidad y cookies no debe seguir usando nuestros productos y servicios.
          </Paragraph2>
          <Paragraph2>
            Para todos los efectos, en el presente instrumento, los términos definidos en “Términos y Condiciones Generales de CUKAP”, se entenderán
            según dichas definiciones.
          </Paragraph2>
          <Subtitle1>
            Privacidad del usuario
          </Subtitle1>
          <Subtitle2>
            a. Recopilación de Información
          </Subtitle2>
          <Paragraph2>
            Al acceder a la Página Web y/o App Móvil, CUKAP recoge cierta información sobre los usuarios, motivo por el cual al registrarse y navegar en la
            Plataforma o utilizar nuestros servicios y productos, el usuario o visitante presta su consentimiento para que se utilice su información
            personal de acuerdo a las políticas que se describen a continuación. El presente documento es parte integrante de los
            “Términos y Condiciones Generales de CUKAP”.
          </Paragraph2>
          <Paragraph2>
            CUKAP no recopila información personal cuando usted accede al sitio web, a menos que usted voluntariamente y con conocimiento nos
            proporcione su identificación e información tal como nombre, correo electrónico, o cualquier otra.
          </Paragraph2>
          <Paragraph2>
            Al acceder, navegar y registrarse en nuestro sitio web, o al acceder a nuestros productos y servicios, terceros de confianza autorizados
            por CUKAP también pueden recopilar cierta información técnica y de enrutamiento acerca de su equipo (también conocido como variables
            ambientales) para facilitar el uso del Sitio Web y/o App Móvil y de sus servicios. Cuando navegue por Internet, el navegador de Internet
            (como Chrome, Edge, Internet Explorer, Opera, Mozilla Firefox o Safari) transmite automáticamente cierta información a CUKAP cada vez
            que accede a contenidos en uno de nuestros dominios de internet. Ejemplos de dicha información incluyen información proporcionada en el
            encabezado de solicitud HTTP, JavaScript o herramientas técnicas similares, incluyendo el número IP, el momento de la solicitud, la
            dirección URL de su solicitud, navegador que utiliza e información sobre el sistema operativo de su computador, ubicación y otros.
            Toda esta información puede ser recogida y utilizada por CUKAP para ayudar a mejorar nuestra oferta para usted.
          </Paragraph2>
          <Paragraph2>
            Los motivos de CUKAP para recopilar estos datos tienen como finalidad obtener información sobre cómo los visitantes usan la página web y
            la forma en que mejor podemos ajustar el Sitio Web y/o App Móvil, su contenido y funcionalidad para satisfacer las necesidades de
            nuestros usuarios.
          </Paragraph2>
          <Paragraph2>
            Si usa un dispositivo móvil también podremos recopilar datos que lo identifiquen. A su vez, al momento de Registrarte en nuestra página
            web y solicitar nuestros productos o servicios, deberá ingresar los datos que le soliciten, tales como nombre, domicilios, correos
            electrónicos, números telefónicos, y otros documentos requeridos. Los usuarios, garantizan y son exclusivamente responsables de la
            veracidad, exactitud, vigencia y autenticidad de toda información proporcionada, facilitada y/o registrada en nuestra Página Web,
            comprometiéndose a mantenerla debidamente actualizada.
          </Paragraph2>
          <Subtitle2>
            b. Sitio web de terceros
          </Subtitle2>
          <Paragraph2>
            El Sitio Web y/o App Móvil de CUKAP puede contener enlaces a sitios web pertenecientes y operados por terceros.
            Estos enlaces están presentes para su conveniencia e información. CUKAP no controla estos sitios web de terceros y no se hace
            responsable de sus prácticas de privacidad o su contenido. CUKAP no controla la recopilación de información y
            políticas de distribución de este tipo de sitios web que no sean los que están bajo el control de CUKAP. Los terceros
            también pueden establecer sus propias cookies y / o Web beacons que pueden ser utilizados para identificar algunas de sus preferencias o
            reconocer si ha tenido previamente contacto con los terceros. CUKAP no controla el uso de esta tecnología por parte de terceros,
            la información que recogen, o cómo utilizan dicha información. Usted debe dirigir todas las inquietudes con respecto a cualquier sitio
            web de terceros al administrador del sitio o webmaster de dichos sitios.
          </Paragraph2>
          <Subtitle2>
            c. Aplicaciones de terceros
          </Subtitle2>
          <Paragraph2>
            CUKAP puede poner a disposición de ustedes, aplicaciones de terceros a través del Sitio Web y/o App Móvil o de sus productos y servicios.
            La información recogida por CUKAP cuando se habilita una aplicación de terceros se rige en virtud de esta Política de Privacidad.
            La información recopilada por el tercero se rige por las políticas de privacidad del tercero.
          </Paragraph2>
          <Subtitle1>
            Información
          </Subtitle1>
          <Paragraph2>
            Para poder utilizar los productos y servicios de CUKAP, usted debe registrarse para obtener una cuenta de usuario.
            Para esto CUKAP le pedirá que suministre voluntariamente cierta información sobre usted rellenando y enviando un formulario en línea.
            Dicha información puede incluir su nombre, número de teléfono, tarjeta de crédito u otra información de facturación, dirección
            de correo electrónico y direcciones postales de contacto.
          </Paragraph2>
          <Paragraph2>
            Al comprar prductos físicos de CUKAP, se le puede pedir proporcionar un número de tarjeta de crédito, cuenta y/o información de facturación.
            Esta información es recogida y procesada por los servicios de pago en línea que podemos utilizar para el procesamiento de pagos.
          </Paragraph2>
          <Paragraph2>
            Usted no está obligado a proporcionar toda la información solicitada, sin embargo, si usted elige retener información solicitada,
            es posible que no seamos capaces de proporcionarle todos o algunos de los productos y servicios.
            Dependiendo del servicio o producto, parte de la información que le pedimos que nos proporcione será obligatoria y otra opcional.
            Si usted no proporciona la información obligatoria con respecto a una actividad o compra en particular, usted no podrá participar en
            esa actividad o hacer dicha compra. CUKAP le informará del carácter obligatorio y/o facultativo de la información solicitada y/o requerida.
          </Paragraph2>
          <Paragraph2>
            Cuando usted envía información personal a través del Sitio Web y/o App Móvil de CUKAP, usted entiende y acepta que esta información puede
            ser transferida a través de fronteras internacionales y puede ser almacenada y procesada en cualquiera de los países en los que CUKAP
            y sus filiales y subsidiarias mantienen oficinas o servidores de alojamiento web. Usted también acepta que, en ciertos países, la
            recolección, transferencia, almacenamiento y procesamiento de su información puede ser llevada a cabo por proveedores de confianza de CUKAP.
            Estos proveedores están obligados por contrato a no utilizar su información personal para sus propios fines o proporcionarse a terceros.
          </Paragraph2>
          <Paragraph2>
            CUKAP puede establecer relaciones con varios proveedores de servicios cuyos servicios pueden estar disponibles para usted a través
            de la página web y/o App Móvil de CUKAP o a través de otros sitios web que están vinculadas a la Página Web de CUKAP.
            Por lo general, estos proveedores ofrecen servicios que a los clientes de CUKAP podrían serle de utilidad, tales como los que se pueden
            utilizar conjuntamente con productos y servicios de CUKAP. Para utilizar estos servicios de terceros, es posible que deba proporcionar
            información personal a dichos proveedores. Salvo disposición expresa la información personal que usted proporcione mientras visita el
            sitio web de un proveedor estará sujeta a la política de privacidad publicada en ese sitio, y la información personal que usted
            proporcione mientras este en este sitio web en conjunto con el servicio de un proveedor estará sujeto a la presente Política de Privacidad.
            Por ejemplo, la tarjeta de crédito o información de pago introducida como parte del proceso de rellenar un formulario de compra o
            registro recogido por el registro de un tercero y/o servicios comerciales está sujeto a las políticas de privacidad de ese tercero.
            Usted debe ser consciente de que los acuerdos de CUKAP con estos proveedores de servicios a menudo establecen que van a compartir
            información personal. En tales casos, CUKAP puede utilizar esta información de una manera consistente con esta Política de Privacidad.
          </Paragraph2>
          <Paragraph2>
            Los Usuarios autorizan y prestan su consentimiento para que CUKAP tenga acceso permanente, a la totalidad de la información contenida
            en su cuenta en la Plataforma Web, y en general toda Información Personal e información generada, utilizada y contenidos alojados en su
            Cuenta de Usuario.
          </Paragraph2>
          <Paragraph2>
            Los Usuarios registrados en la Plataforma Web y/o app móvil, podrán solicitar la baja o cierre de su registro y/o cierre y/o
            eliminación de su cuenta.
          </Paragraph2>
          <Subtitle1>
            Boletín de noticias
          </Subtitle1>
          <Paragraph2>
            Es posible que se le brinde la oportunidad de suscribirse a un boletín de noticias y actualizaciones periódicas de productos.
            Este boletín es una herramienta de marketing que permite a CUKAP informarle de nuevos productos y servicios, actualizaciones, así como
            otras noticias de interés para la empresa. Para acceder a la suscripción debe proporcionar su nombre y apellido, su dirección de correo
            electrónico y otra información. Opcionalmente, se le solicitará que proporcione el nombre de la empresa que usted representa.
            Se le da la oportunidad de elegir el tipo de información que FRACTTAL le proporcionará a usted y se le dará la oportunidad de optar por
            no recibir dicha información escribiendo a soporte@cukap.cl.
          </Paragraph2>
          <Subtitle1>
            Archivos
          </Subtitle1>
          <Paragraph2>
            CUKAP recoge y almacena los archivos que cargue, descargue, o acceda a través de los productos y servicios ofrecidos a través del Sitio Web y/o App Móvil.
            Entendemos que estos archivos pueden ser confidenciales o contener información sensible. En consecuencia, CUKAP no comparte ni autoriza
            a terceros acceder a dichos archivos. Sin embargo, ningún sistema de acceso restringido en el almacenamiento y recuperación de datos puede
            ser hecho completamente impenetrable. Aunque CUKAP utiliza una serie de tecnologías y procedimientos de seguridad para asegurarse de que
            los datos en el sitio web son seguros, puede ser posible que un tercero no autorizado acceda, vea, copie, modifique y distribuya los datos
            y archivos guardados en la Web o por medio de productos y servicios a pesar de los esfuerzos de CUKAP.
          </Paragraph2>
          <Subtitle1>
            Cuenta de usuario
          </Subtitle1>
          <Paragraph2>
            Como se mencionó anteriormente, usted podrá crear una cuenta de usuario que contenga su información personal. Se requiere una cuenta de
            usuario para el uso de los productos y servicios ofrecidos en el Sitio Web y/o App Móvil. Una vez creada la cuenta de usuario, usted
            podrá acceder, revisar y actualizar su información de cuenta de usuario en línea a través del Sitio Web y/o App Móvil, y puede modificar
            su información personal en cualquier momento. También puede acceder y modificar su información y preferencias de privacidad personales
            contactandonos en soporte@cukap.cl.
          </Paragraph2>
          <Paragraph2>
            Si usted desea desactivar su cuenta de usuario, puede hacerlo poniéndose en contacto con CUKAP en soporte@cukap.cl.
          </Paragraph2>
          <Subtitle1>
            Información realtiva a niños
          </Subtitle1>
          <Paragraph2>
            Ley de Protección (COPPA) de privacidad de los niños en línea fue aprobada por el Congreso de Estados Unidos en noviembre de 1998.
            COPPA provee a los padres los derechos específicos respecto a la privacidad de sus hijos. Para obtener información adicional y recursos
            en COPPA, por favor visite el sitio web de la Comisión Federal de Comercio en http://www.ftc.gov/.
          </Paragraph2>
          <Paragraph2>
            El Sitio Web y/o App Móvil y los productos o servicios de CUKAP no están dirigidos a niños menores de 18 años de edad y, por lo tanto,
            COPPA no se aplica. Sin embargo, reconocemos que los niños menores de 18 pueden acceder a este Sitio Web y/o App Móvil o los productos
            y servicios de compra. Los padres y tutores legales pueden solicitarnos revisar, eliminar o detener la recopilación de la información
            de identificación personal de su hijo. Usted puede hacerlo poniéndose en contacto con nosotros por correo electrónico a soporte@cukap.com.
          </Paragraph2>
          <Subtitle1>
            Asegurar la transmisión y almacenamiento de información
          </Subtitle1>
          <Paragraph2>
            CUKAP se compromete a proteger la seguridad de su información personal. Utilizamos una variedad de tecnologías y procedimientos de
            seguridad para ayudar a proteger su información personal. CUKAP opera redes de datos seguras protegidas por sistemas de cortafuegos
            estándar de la industria y protección de contraseña avanzada. Nuestras políticas de seguridad y privacidad son revisadas periódicamente y
            mejoradas según sea necesario, y sólo las personas autorizadas tendrán acceso a la información proporcionada por nuestros usuarios.
            CUKAP toma medidas para asegurar que su información sea tratada con seguridad y de acuerdo con esta Política de Privacidad.
            Desafortunadamente, ninguna transmisión de datos por Internet puede garantizarse segura. Como resultado, mientras nos esforzamos por
            proteger su información personal, no podemos garantizar la seguridad de ninguna información que usted nos transmita o de nuestros productos
            o servicios en línea, y debe utilizar estos servicios bajo su propio riesgo.
          </Paragraph2>
          <Paragraph2>
            Tratamos la información que usted nos proporciona, incluyendo los archivos, como información confidencial; por lo tanto dicha información
            está sujeta a los procedimientos de seguridad de nuestra empresa y a las políticas corporativas en materia de protección y uso de
            información confidencial. La información de identificación personal se almacena en un servidor con características de seguridad físicas y
            electrónicas habituales en la industria, incluyendo la utilización de los procedimientos de usuario / contraseña y cortafuegos electrónicos
            diseñados para bloquear el acceso no autorizado. Debido a que las leyes aplicables a la información personal varían según el país,
            nuestras oficinas u otras operaciones de negocios pueden poner en marcha medidas adicionales que varían dependiendo de los requisitos
            legales aplicables. Todos los empleados están obligados a cumplir nuestras políticas de privacidad y seguridad. Su información es sólo
            accesible para los empleados que realizan el apoyo técnico del servicio.
          </Paragraph2>
          <Paragraph2>
            Es su responsabilidad mantener su contraseña confidencial. No comparta esta información con nadie. Si está compartiendo una computadora y/o App Móvil
            con alguien siempre debe cerrar la sesión antes de dejar cualquier sitio o servicio para proteger el acceso a su información de los
            usuarios posteriores.
          </Paragraph2>
          <Subtitle1>
            Uso y divulgación de la información
          </Subtitle1>
          <Subtitle2>
            a. Cómo se utiliza su información personal
          </Subtitle2>
          <Paragraph2>
            CUKAP recopila su información para comprender mejor cómo los visitantes usan la página web y la forma en que mejor podemos ajustar el
            Sitio Web y/o App Móvil, su contenido y funcionalidad para satisfacer las necesidades de nuestros usuarios.
          </Paragraph2>
          <Paragraph2>
            Podemos utilizar su dirección de correo electrónico para enviar un correo de confirmación y, si es necesario, podemos utilizar otra
            información que proporcione contacto con usted con el fin de procesar la compra o servicio que usted ha seleccionado.
          </Paragraph2>
          <Paragraph2>
            Si se suscribe a un servicio o cuando se registra a un producto, su información personal se utiliza para registrar sus derechos,
            en su caso, tal como la asistencia técnica o de otros beneficios que pueden estar disponibles para los usuarios registrados.
          </Paragraph2>
          <Paragraph2>
            A menos que opte por lo contrario, su información personal también puede ser utilizada para mantenerlo informado sobre los nuevos
            productos, actualizaciones de producto, parches, ofertas especiales y otros productos y servicios de CUKAP y terceros seleccionados.
          </Paragraph2>
          <Paragraph2>
            CUKAP puede recopilar información sobre el uso de la Web, tales como los tipos de servicios utilizados y el número de usuarios que
            recibimos diariamente. Esta información se recoge sin identificar a ningún usuario individual. CUKAP puede utilizar esto para el análisis
            estadístico, la comercialización, o con fines de promoción.
          </Paragraph2>
          <Paragraph2>
            CUKAP reconoce y aprecia la importancia del uso responsable de la información recogida en este sitio web y/o App Móvil.
            Sin su consentimiento, CUKAP no comunicará ninguna información a usted en relación con productos, servicios u ofertas especiales
            disponibles de CUKAP o sus afiliados, aunque podemos encontrar necesario para comunicarnos con usted en relación con su uso de los
            servicios de este Sitio Web y/o App Móvil. Excepto en las circunstancias particulares descritas en esta Política de Privacidad, CUKAP
            no proporcionará su nombre a otras empresas u organizaciones sin su consentimiento.
          </Paragraph2>
          <Subtitle2>
            b. Divulgación de la información a terceros
          </Subtitle2>
          <Paragraph2>
            En caso de requerimientos judiciales o que la ley lo permita o exija, o si es estrictamente necesario para prevenir, detectar o perseguir
            fraudes y actos criminales; CUKAP podrá compartir todo tipo de información que haya recabado o recibido, incluyendo la Información Personal,
            con los organismos, entidades, instituciones y autoridades gubernamentales pertinentes. CUKAP podrá, sin previo requerimiento judicial, ni
            autorización de Usuarios, y ante la sospecha por parte de cualquiera de sus socios, directores, trabajadores y dependientes en general,
            o de alguna de las Empresas de Outsourcing, de la presencia y/o configuración de algún ilícito, realizar las denuncias pertinentes y
            entregar la información relacionada al caso. A su vez, CUKAP podrá suministrar todo tipo de información que haya recabado o recibido,
            incluyendo la Información Personal, a entidades que intervengan en la resolución de conflictos tales como: Compañías de Seguros, Mediadores
            y/o Jueces Árbitros Particulares, etcétera.
          </Paragraph2>
          <Subtitle1>
            Cookies
          </Subtitle1>
          <Paragraph2>
            Los Usuarios, y general cualquier visitante del Sitio Web y/o App Móvil o usuario de nuestros productos y servicios, faculta expresamente
            a CUKAP, para que emplee sistemas de seguimiento mediante la utilización de “Cookies”, esto es, una pequeña cantidad de información,
            archivos y/o código de software que se almacenan e instalan en tu ordenador o en tu dispositivo móvil, por un periodo de tiempo
            indeterminado, ayudando a personalizar los servicios prestados por CUKAP y facilitar así el uso de nuestro productos, personalizar
            nuestro sitio web y hacer ofertas según sus necesidades, lo que le proporcionará una mejor experiencia en línea con nosotros.
          </Paragraph2>
          <Paragraph2>
            Esta Política de Privacidad y Cookies es aplicable a las cookies y a las tecnologías similares (de ahora en adelante, «cookies»).
            Puedes borrar todas las cookies de tu navegador en cualquier momento.
          </Paragraph2>
          <Subtitle2>
            La utilización de las Cookies por parte de CUKAP tiene por objeto:
          </Subtitle2>
          <Paragraph2>
            •	Mostrar nuestra Página Web y/o App Móvil, ayudar a que funcione correctamente, crear su cuenta de usuario, iniciar su sesión,
            recordar su clave de acceso, registrar su actividad y gestionar los servicios que usted contrate.
          </Paragraph2>
          <Paragraph2>
            •	Recordar sus preferencias y búsquedas pasadas, contribuyendo a que pueda dar un uso más eficiente y efectivo a nuestra Plataforma
            Web y/o App Móvil, recordando datos tales como, por ejemplo, y sin que la siguiente enunciación sea taxativa ni limitativa, búsquedas
            recientes, preferencias, áreas de búsqueda, idioma, tipo de moneda.
          </Paragraph2>
          <Paragraph2>
            •	Análisis de las páginas web en que navegas y las búsquedas realizadas, a fin de mejorar nuestra iniciativa comercial, personalizando
            los contenidos y servicios presentados, y para promover y hacer cumplir las reglas de la Plataforma Web y/o Aplicación. A su vez recoger
            datos relacionados a las páginas web que has visitado, páginas de referencia/salida en las que has entrado y salido, tipo de plataforma
            que utilizas, información de fecha/hora y datos como el número de clics que has hecho en una página determinada, movimientos de ratón y
            desplazamientos, palabras de búsqueda que utilizas, y texto que escribes cuando utilizas nuestra Plataforma y/o Aplicación.
          </Paragraph2>
          <Paragraph2>
            •	Identificar errores o problemas de funcionamiento del sitio web, optimizarla y mejorarla. 
          </Paragraph2>
          <Paragraph2>
            •	No utilizamos cookies para recoger información sobre sus visitas a otros sitios web, ni averiguamos ninguna información personal
            identificable sobre usted, aparte de lo que usted voluntariamente nos proporcione en su trato con CUKAP. Las cookies en circunstancias
            normales no pueden corromper o dañar los archivos de computadora, programas o informáticos.
          </Paragraph2>
          <Paragraph2>
            •	Además, los proveedores de servicios que utilizamos para anuncios, y/o entregar nuestros e-mails usan cookies de sesión y cookies
            persistentes, para realizar un seguimiento del número de veces que se accede a la página web y si el sitio se accede desde un anuncio.
            No hay cookies en los anuncios o correos electrónicos. Una cookie es colocada en el equipo sólo cuando se hace clic en un anuncio o al
            abrir el correo electrónico. La cookie generada a partir de la publicidad o el correo electrónico no contiene ninguna información de
            identificación personal y permanecerá en el disco duro hasta que lo elimine.
          </Paragraph2>
          <Paragraph2>
            •	Usted puede configurar su navegador para bloquear las cookies, aunque si lo hace puede afectar negativamente su capacidad para
            realizar ciertas transacciones, utilizar ciertas funciones y acceder a determinados contenidos en nuestro Sitio Web y/o App Móvil.
          </Paragraph2>
          <Paragraph2>
            •	Asimismo, le informamos de que el responsable no tiene previsto realizar elaboraciones de perfiles con los datos recabados mediante
            las cookies empleadas en el sitio web. Si en el futuro fuesen a llevarse a cabo, se informaría previamente a los usuarios y se les
            solicitaría el pertinente consentimiento. Esta política de cookies está de acuerdo con la normativa de protección de datos personales de
            la República de Chile y RGPD de la UE. 
          </Paragraph2>
          <Subtitle1>
            Spam
          </Subtitle1>
          <Paragraph2>
            CUKAP no acepta ni tolera conductas consideradas «spamming», ya sea por parte de Usuarios, y en general cualquier visitante de la
            Plataforma Web y/o App Móvil, sea esta realizada de cualquier modo. Queda absolutamente prohibido el envío indiscriminado de mensajes
            de cualquier naturaleza.
          </Paragraph2>
          <Paragraph2>
            CUKAP podrá suspender, inhabilitar y/o cerrar temporalmente o definitivamente a aquellos Usuarios que violen esta política.
          </Paragraph2>
          <Subtitle1>
            Almacenamiento de la información
          </Subtitle1>
          <Paragraph2>
            CUKAP cumple con toda la normativa aplicable en materia de medidas de seguridad aplicables a la Información Personal, empleando para
            estos efectos los estándares de la industria respecto de protección de la confidencialidad de su Información Personal.
            Sin perjuicio de lo anterior, y si bien CUKAP emplea las medidas necesarias para resguardar la seguridad y confidencialidad de la
            Información Personal, la seguridad perfecta no existe en Internet, por lo que CUKAP no se hace responsable por interceptaciones ilegales
            o violación de sus sistemas o bases de datos por parte de personas no autorizadas, ni el uso indebido de la información obtenida por
            esos medios.
          </Paragraph2>
          <Subtitle1>
            Contacto
          </Subtitle1>
          <Paragraph2>
            Para cualquier aclaración respecto de la presente normativa, y en general de la normativa que rige los productos y servicios
            prestados por CUKAP y nuestra Plataforma Web y/o App Móvil, así como respecto a su Información Personal, o si cree que hubo un caso de
            incumplimiento de esta política de privacidad en relación a su información personal, puede enviarnos un correo electrónico indicando
            en detalle el motivo de tu comunicación a soporte@cukap.cl.
          </Paragraph2>
          <Paragraph2>
            Copyright © 2020 Cukap SpA. Todos los derechos reservados. Este sitio web, la App Móvil y los productos que se acompañan y la
            documentación son propiedad intelectual de Cukap SpA y/o sus licenciantes y están protegidos por las leyes de copyright y tratados
            internacionales de propiedad intelectual. CUKAP y el logotipo relacionado, y todos los nombres de productos y servicios relacionados,
            marcas de diseño y lemas son marcas registradas y/o marcas comerciales registradas de Cukap SpA. Todas las demás marcas de productos y
            servicios contenidos en este documento son marcas comerciales de sus respectivos propietarios. Cualquier uso de las marcas o logos de
            CUKAP o terceros sin el consentimiento previo por escrito de Cukap SpA o el propietario de la marca aplicable está terminantemente
            prohibida.
          </Paragraph2>
        </ContentWrapper>
      </Container>
    </>
  )
}

export default SignIn
