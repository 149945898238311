import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  //background-color: #7fb5b5;
  background-color: white;
`;

export const FooterWrap = styled.div`
  padding: 0px 24px 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1100px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SocialLogo = styled(Link)`
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;

  @media screen and (max-width: 820px) {
    margin-bottom: 10px;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
`;

export const SocialIconLink = styled.a`
  //color: #fff;
  color: #7fb5b5;
  font-size: 30px;

  &:hover {
    transform: scale(1.3);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    //color: #fff;
    color: grey;
  }
`;

export const ImgWrap = styled.div`
  max-width: 100px;
  height: 100%;
  margin-left: --10px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const InfoBg2 = styled.div`
  width: 100%;
  height: 100%;
  //overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(128, 181, 181, 1) 1%,
    rgba(255, 255, 255, 1) 1%
  );
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  -o-bject-fit: cover;
  object-fit: cover;
`;

export const NavBtnLink = styled(Link)`
  white-space: nowrap;
  color: #7fb5b5;
  margin-bottom: 5px;
  font-size: 13px;

  @media screen and (max-width: 820px) {
    margin-bottom: 20px;
  }
`;

export const WebsiteRights = styled.small`
  //color: #fff;
  color: #7fb5b5;
  margin-bottom: 5px;
  font-size: 13px;

  @media screen and (max-width: 820px) {
    margin-bottom: 20px;
  }
`;