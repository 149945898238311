import React from 'react'
import {
  Container,
  HeroContainer,
  HeroBg,
  ImageBg,
  ContentWrapper,
  Title,
  Paragraph1
} from './SigninElements';

import bg1 from '../../images/bg-1.svg';

const SignIn = () => {
  return (
    <>
      <Container>
        <HeroContainer>
          <HeroBg>
            <ImageBg
              src={bg1}
              alt={'bg-1'}
            />
          </HeroBg>
          {/* <Title>Política de Privacidad</Title> */}
        </HeroContainer>
        <ContentWrapper>
          <Title>Próximamente</Title>
          <Paragraph1>soporte@cukap.cl</Paragraph1>
        </ContentWrapper>
      </Container>
    </>
  )
}

export default SignIn
